import React from 'react';
import { createRoot } from 'react-dom/client';
import CookieConsent from 'react-cookie-consent';

const container = document.getElementById('cookie-banner');
const root = createRoot(container);
root.render(
  <CookieConsent
    buttonText="Aceitar"
    buttonClasses="btn btn-primary flat mb-3"
    buttonStyle={{}}
    buttonWrapperClasses="container-fluid text-right"
    disableButtonStyles
    style={{ zIndex: 99999 }}
  >
    Estamos em conformidade com a Lei Geral de Proteção de Dados (LGPD) e
    utilizamos cookies para oferecer uma melhor experiência.
    Ao clicar em Aceitar, você consente com a utilização de cookies.
  </CookieConsent>,
);
